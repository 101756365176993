import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import consola from "consola";
import autoAnimate from '@formkit/auto-animate';

const App: React.FC = () => {
    const [visibleSettings, setVisibleSettings] = useState<boolean>(false);
    const [digits, setDigits] = useState<number>(3);
    const [highscore, setHighscore] = useState<number>(0);
    const [code, setCode] = useState<number[]>([]);
    const [guess, setGuess] = useState<number[]>([]);
    const [hint, setHint] = useState<string>('');
    const [tries, setTries] = useState<number>(0);
    const [guessHistory, setGuessHistory] = useState<{ guess: number[], hint: string }[]>([]);
    const guessHistoryRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setCode(generateRandomCode(digits));
        setGuess(new Array(digits).fill(0));
    }, [digits]);

    useEffect(() => {
        autoAnimate(guessHistoryRef.current!);
    }, [guessHistory]);

    const generateRandomCode = (length: number): number[] => {
        const code = [];
        for (let i = 0; i < length; i++) {
            code.push(Math.floor(Math.random() * 10));
        }
        consola.ready('Geheimer Code:', code);
        return code;
    };

    const calculateHints = (guess: number[], code: number[]): string => {
        let correctPositionCount = 0;
        let correctValueCount = 0;
        let incorrectCount = 0;

        for (let i = 0; i < guess.length; i++) {
            if (guess[i] === code[i]) {
                correctPositionCount++;
            } else if (code.includes(guess[i])) {
                correctValueCount++;
            } else {
                incorrectCount++;
            }
        }

        const correctPositionText = correctPositionCount > 0 ? `${correctPositionCount} Zahl${correctPositionCount > 1 ? 'en' : ''} an richtiger Position` : '';
        const correctValueText = correctValueCount > 0 ? `${correctValueCount} Zahl${correctValueCount > 1 ? 'en' : ''} korrekt aber an falscher Stelle` : '';
        const incorrectText = incorrectCount > 0 ? `${incorrectCount} Zahl${incorrectCount > 1 ? 'en' : ''} komplett falsch` : '';

        const hints = [correctPositionText, correctValueText, incorrectText].filter(Boolean).join(', ');

        return hints || 'Alle Zahlen sind komplett falsch';
    };

    const handleInputChange = (index: number, value: string) => {
        const newGuess = [...guess];
        newGuess[index] = parseInt(value);
        setGuess(newGuess);
    };

    const handleGuess = () => {
        const newHint = calculateHints(guess, code);
        let crackedhighscore: boolean = false;
        setHint(newHint);

        if (newHint === `${digits} Zahlen an richtiger Position`) {
            if (tries < highscore || highscore === 0) {
                setHighscore(tries + 1);
                crackedhighscore = true;
            }
            if (crackedhighscore) {
                alert(`Glückwunsch! Du hast den Code in ${tries + 1} Versuchen geknackt und den Highscore gebrochen!`);
                consola.success(`Glückwunsch! Du hast den Code in ${tries + 1} Versuchen geknackt und den Highscore gebrochen!`);
            } else {
                alert(`Glückwunsch! Du hast den Code in ${tries + 1} Versuchen geknackt!`);
                consola.success(`Glückwunsch! Du hast den Code in ${tries + 1} Versuchen geknackt!`);
            }

            setCode(generateRandomCode(digits));
            setGuess(new Array(digits).fill(0));
            setTries(0);
            setHint('');
            setGuessHistory([]);
            return;
        }

        setTries(tries + 1);
        setGuessHistory([...guessHistory, { guess, hint: newHint }]);
    };

    const handleSetDigits = (newDigits: number) => {
        setDigits(newDigits);
    };

    return (
        <div className="container">
            <h1>CodeCrackers</h1>
            <div className="input-container">
                {guess.map((digit, index) => (
                    <input
                        key={index}
                        className="number-input"
                        type="number"
                        min={0}
                        max={9}
                        value={digit}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                ))}
            </div>
            <button className="submit-button" onClick={handleGuess}>Submit Guess</button>
            <div className="box">
                <div className="hint-and-tries">
                    <p>{hint}</p>
                    <div>
                        <h5>Versuche: {tries}</h5>
                        <h5>Highscore: {highscore}</h5>
                    </div>
                    <button className="setting-button" onClick={() => setVisibleSettings(!visibleSettings)}>Einstellungen</button>
                </div>
                <hr/>
                <div className="scrollable-box" ref={guessHistoryRef}>
                    <h3>Versucheverlauf:</h3>
                    <ol>
                        {guessHistory.map((entry, index) => (
                            <li key={index}>
                                <p>Versuch: {entry.guess.join(', ')}</p>
                                <p>Hinweis: {entry.hint}</p>
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
            <div className="container">
                <div className={`settings ${visibleSettings ? 'visible' : 'hidden'}`}>
                    <h3>Stellenanzahl des Codes:</h3>
                    <div className="set-digits-container">
                        <button className="setting-button" onClick={() => handleSetDigits(3)}>Setze 3 Stellen</button>
                        <button className="setting-button" onClick={() => handleSetDigits(4)}>Setze 4 Stellen</button>
                        <button className="setting-button" onClick={() => handleSetDigits(5)}>Setze 5 Stellen</button>
                    </div>
                    <div className="set-digits-container">
                        <button className="setting-button" onClick={() => handleSetDigits(6)}>Setze 6 Stellen</button>
                        <button className="setting-button" onClick={() => handleSetDigits(7)}>Setze 7 Stellen</button>
                        <button className="setting-button" onClick={() => handleSetDigits(8)}>Setze 8 Stellen</button>
                    </div>
                </div>
            </div>
            <div className="disclaimer">
                <h5>Made with ❤ by Lilly and Basti</h5>
                <h5>Copyright 2024 MyDarkMe</h5>
            </div>
        </div>
    );
};

export default App;
